.App {
  text-align: center;
}

.App-logo {
  animation: moveX infinite 10s linear;
  height: auto;
  width: 40%;
  color: red;
  position: relative;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button {
  margin: 10px;
  height: 35px;
  width: 35px;
  font-size: 15px;
  border-radius: 2px;
}

@keyframes moveX {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(75%);
  }
  50% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(-75%);
  }
  100% {
    transform: translateX(0%);
  }
}
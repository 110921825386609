body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: moveX infinite 10s linear;
          animation: moveX infinite 10s linear;
  height: auto;
  width: 40%;
  color: red;
  position: relative;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button {
  margin: 10px;
  height: 35px;
  width: 35px;
  font-size: 15px;
  border-radius: 2px;
}

@-webkit-keyframes moveX {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  25% {
    -webkit-transform: translateX(75%);
            transform: translateX(75%);
  }
  50% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  75% {
    -webkit-transform: translateX(-75%);
            transform: translateX(-75%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes moveX {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  25% {
    -webkit-transform: translateX(75%);
            transform: translateX(75%);
  }
  50% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  75% {
    -webkit-transform: translateX(-75%);
            transform: translateX(-75%);
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
